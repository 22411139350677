$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-value-card {
  .cmp-image__title {
    display: none;
  }

  .teaser {
    min-height: rem(230);
    padding: rem(25);
    color: map-get($colors, light-text);
    @include text-base;

    .cmp-teaser__title {
      @include heading3;
      margin-bottom: rem(10);

      .cmp-teaser__title-link {
        color: map-get($colors, light-text);
        text-decoration: none;
      }
    }

    .cmp-teaser__action-link {
      color: inherit;
    }

    @include media('>=screenSmall') {
      min-height: rem(350);
      padding: rem(65) rem(30);
    }

    .cmp-teaser__title {
      margin-bottom: rem(10);
    }
  }

  .container > .cmp-container {
    background-position: 98% 98%;
    background-size: initial !important;
  }

  // Fix to prevent background bleed on value cards backgrounds
  .cmp-container__bleed {
    position: relative;
    width: auto;
    margin: 0;
  }
}
